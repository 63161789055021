<template>
  <div>
    <el-dialog
      :title="title + '菜单'"
      :visible.sync="dialogVisible"
      width="650px"
    >
      <el-form
        :model="userFrom"
        :rules="rules"
        ref="userFrom"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item label="级别" prop="level"> -->
        <!-- <el-input v-model="userFrom.level"></el-input> -->
        <el-form-item label="上级菜单" prop="pid">
          <treeselect
            v-model="userFrom.pid"
            :options="menuOptions"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="选择上级菜单"
          />
          <!-- <el-cascader
              size="mini"
              ref="warehouseList"
              @change="getRepository()"
              v-model="userFrom.pid"
              :options="classListOption"
              :props="{
                checkStrictly: true,
                value: 'goodsClassId',
                label: 'goodsClassName',
                emitPath: false,
                checkStrictly: true,
              }"
              clearable
            ></el-cascader> -->
        </el-form-item>
        <!-- </el-form-item> -->
        <el-form-item label="菜单类型" prop="menuType">
          <el-radio v-model="userFrom.menuType" :label="201">目录</el-radio>
          <el-radio v-model="userFrom.menuType" :label="202">菜单</el-radio>
        </el-form-item>
        <el-form-item label="菜单图标" prop="menuIcon">
          <el-input v-model="userFrom.menuIcon"></el-input>
        </el-form-item>
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="userFrom.menuName"></el-input>
        </el-form-item>
        <el-form-item label="路由地址" prop="menuRout">
          <el-input v-model="userFrom.menuRout"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="menuSort">
          <el-input v-model="userFrom.menuSort"></el-input>
        </el-form-item>
        <el-form-item label="菜单状态" prop="menuState">
          <el-radio v-model="userFrom.menuState" :label="101">启用</el-radio>
          <el-radio v-model="userFrom.menuState" :label="102">禁用</el-radio>
        </el-form-item>
        <el-form-item label="外部链接">
          <el-input v-model="userFrom.menuUrl"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="userFrom.remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colse">取 消</el-button>
        <el-button type="primary" @click="submitForm('userFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import * as userApi from '@/api/user'
export default {
  components: { Treeselect },

  data() {
    return {
      dialogVisible: false,
      userFrom: {
        level: '',
        menuIcon: '',
        menuName: '',
        menuRout: '',
        menuSort: '',
        menuState: 101,
        menuType: 201,
        remarks: '',
        userId: '',
        id: '',
      },
      menuOptions: [],
      rules: {
        pid: [{ required: true, message: '请选择上级菜单', trigger: 'blur' }],
        menuIcon: [
          { required: true, message: '请输入icon图标', trigger: 'blur' },
        ],
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],

        menuRout: [
          { required: true, message: '请输入页面路由地址', trigger: 'blur' },
        ],
        menuSort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        menuState: [
          { required: true, message: '请选择菜单状态', trigger: 'blur' },
        ],
        menuType: [
          { required: true, message: '请选择菜单类型', trigger: 'blur' },
        ],
      },
      isimg: false,
      title: '新增',
      classListOption: [],
    }
  },

  created() {},

  mounted() {},

  methods: {
    init(val, row) {
      this.title = val
      this.dialogVisible = true
      if (val == '新增') {
        this.userFrom = {}
      } else {
        this.userFrom = JSON.parse(JSON.stringify(row))
      }
      let params = {
        data: {
          menuName: '',
          pid: -1,
        },
        page: 1,
        pageSize: 999,
      }
      userApi.mentList(params).then((res) => {
        // this.tableData = res.data.data
        this.menuOptions = []
        const menu = { id: 0, menuName: '主类目', children: [] }
        menu.children = this.handleTree(res.data.data, 'id')
        this.menuOptions.push(menu)
        // this.classListOption = [...this.handleTree(res.data.data, 'pid')]
      })
      // this.menuOptions = [];
      //     const menu = { menuId: 0, menuName: "主类目", children: [] };
      //     menu.children = this.handleTree(response.data, "menuId");
      //     this.menuOptions.push(menu);
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      console.log(node, 'node')
      if (node.child && !node.child.length) {
        console.log(11)
        delete node.child
      }
      return {
        id: node.id,
        label: node.menuName,
        child: node.child,
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    colse() {
      this.userFrom = {
        level: '',
        menuIcon: '',
        menuName: '',
        menuRout: '',
        menuSort: '',
        menuState: 101,
        menuType: 201,
        remarks: '',
        userId: '',
        pid: '',
        id: '',
      }
      this.dialogVisible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.userFrom.pid == 0 && !this.userFrom.pid) {
            this.userFrom.level = 1
          } else if (this.userFrom.pid != 0 && !this.userFrom.pid) {
            this.userFrom.level = 2
          }
          if (!this.userFrom.id) {
            let params = {
              // code: "",
              // data: {
              ...this.userFrom,
              // },
              // time: "",
            }
            let { data } = await this.$base.postAjax(
              '/user/menu/addMenus',
              params
            )
            if (data.code == 200) {
              this.$message.success('成功')
              this.dialogVisible = false
              this.$emit('onSubmit')
            }
          } else {
            let params = {
              // code: "",
              // data: {
              ...this.userFrom,

              // },
              // time: "",
            }
            let { data } = await this.$base.postAjax(
              '/user/menu/updateMenus',
              params
            )
            if (data.code == 200) {
              this.$message.success('成功')
              this.dialogVisible = false
              this.$emit('onSubmit')
            }
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dif {
  display: flex;
}
.attachmentL {
  position: relative;
  margin-right: 10px;
}
.imgwh {
  width: 80px;
  height: 80px;
}
.mask {
  position: absolute;
  right: 7px;
  top: 3px;
  .el-icon-delete {
    font-size: 20px;
    cursor: pointer;
  }
}
.addbox {
  border: 1px dashed #d9d9d9;
  width: 80px;
  height: 80px;
}
.avatar-uploader-icon,
el-icon-delete {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.bgimg {
  width: 60px;
  height: 60px;
}
</style>
