<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>菜单管理</span>
      </div>
      <el-form :inline="true" :model="searchForm" ref="searchRef">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input
            v-model="searchForm.menuName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSubmit"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" @click="resetSearch('searchRef')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div class="form-box">
        <el-button plain type="primary" icon="el-icon-plus" @click="addMan"
          >新增</el-button
        >
        <!-- <el-button icon="el-icon-download" plain type="primary" @click="onBatchExport"
          >导出</el-button
        > -->
        <!-- <el-button icon="el-icon-upload2" plain type="primary" @click="add"
          >导入</el-button
        > -->
      </div>
    </el-card>
    <el-card shadow="never" class="data-list">
      <div slot="header" class="clearfix">
        <span>菜单列表</span>
      </div>
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
          row-key="id"
          :default-expand-all="isExpandAll"
          :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="menuName" label="菜单名称"> </el-table-column>
          <el-table-column prop="menuRout" label="页面路由地址">
          </el-table-column>
          <el-table-column prop="menuSort" label="排序"> </el-table-column>
          <el-table-column prop="menuState" label="状态">
            <template slot-scope="scope">
              {{
                scope.row.menuState == 101
                  ? '启用'
                  : scope.row.menuState == 102
                  ? '禁用'
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column prop="menuType" label="菜单类型">
            <template slot-scope="scope">
              {{
                scope.row.menuType == 201
                  ? '目录'
                  : scope.row.menuType == 202
                  ? '菜单'
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editUser(scope.row)"
                >修改</el-button
              >
              <el-button type="text" size="small" @click="deleteMent(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="pagination">
        <el-pagination
          :hide-on-single-page="total < pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[6, 12, 24, 36, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </el-card>
    <!--  -->
    <addment ref="adduser" @onSubmit="onSubmit" />
  </div>
</template>
<script>
import addment from './mentForm'
import table2excel from 'js-table2excel'
import * as userApi from '@/api/user'
export default {
  data() {
    return {
      searchForm: {
        menuName: '',
        pid: -1,
      },
      currentPage: 1,
      pageSize: 999,
      total: null,
      input: '',
      tableData: [],
      isExpandAll: false,
      img1: require('/src/assets/images/user.png'),
    }
  },
  components: {
    addment,
  },
  methods: {
    addMan() {
      // this.showuser = true;
      // this.$nextTick(() => {
      this.$refs.adduser.init('新增')
      // });
    },
    editUser(val) {
      this.$refs.adduser.init('修改', val)
    },

    //支持导出图片和类型转换

    // async onDelete(id) {
    //   let params = {
    //     code: "",
    //     data: id,
    //     time: "",
    //   };
    //   let { data } = await this.$base.postAjax(
    //     "/mallUser/CustomerMan/deleteCustomerS",
    //     params
    //   );
    //   if (data.code == 200) {
    //     this.$message.success("删除成功");
    //     this.onSubmit();
    //   }
    // },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData()
    },
    //重置
    resetSearch(formName) {
      this.$refs[formName].resetFields()
      this.onSubmit()
    },
    //搜索
    onSubmit() {
      this.currentPage = 1
      this.getData()
    },
    //获取数据
    async getData() {
      let params = {
        data: {
          ...this.searchForm,
        },
        page: this.currentPage,
        pageSize: this.pageSize,
      }
      let res = await userApi.mentList(params)
      if (res.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.total
      } else {
        this.$message.warning(res.msg)
      }
    },
    //监听排序
    sortChange() {},
    //表格数据
    ValueFormatter(a, b, c) {
      if (c && c != null && c != undefined && c != 'null' && c != 'undefined') {
        return c
      } else {
        return '-'
      }
    },
    deleteMent(row) {
      this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let params = {
            id: row.id,
          }
          let res = await userApi.deleteMenus(params)
          if (res.code == 200) {
            this.onSubmit()
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          } else {
            this.$message.warning(res.msg)
          }
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!',
          // })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
  created() {
    this.onSubmit()
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.bgimg {
  width: 60px;
  height: 60px;
}
</style>
